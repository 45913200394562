import React from "react";
import ResourceLinks from "gatsby-theme-carbon/src/components/LeftNav/ResourceLinks";

const links = [
  {
    title: "Logo Library",
    href: "https://drive.google.com/drive/folders/1lq4KXg8aHSiQEtLv-aZiAThAzgXsLgnU?usp=sharing",
  },
];

// shouldOpenNewTabs: true if outbound links should open in a new tab
const CustomResources = () => <ResourceLinks shouldOpenNewTabs links={links} />;

export default CustomResources;
