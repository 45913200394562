import React from "react";

const If = ({ children, data }) => data && children;

const ListBadge = ({ text, type = "normal" }) => {
  return (
    <div className={`labelsidebar ${type === "upcoming" && "navdisable"}`}>
      <If data={type === "normal"}>
        <span>{text}</span>
      </If>
      <If data={type === "new"}>
        <span>{text}</span>
        <div className={`badge ${type}`}>New</div>
      </If>
      <If data={type === "update"}>
        <span>{text}</span>
        <div className={`badge ${type}`}>Update</div>
      </If>
      <If data={type === "upcoming"}>
        <span>{text}</span>
        <div className={`badge ${type}`}>Upcoming</div>
      </If>
    </div>
  );
};

export default ListBadge;